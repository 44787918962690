import React, { useEffect, useState, createRef } from "react";
import "./App.css";

import app, { firestore } from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import ReactPlayer from "react-player/lazy";
import { isMobile } from "react-device-detect";
import { useToasts } from "react-toast-notifications";

// Firebase onfig
const firebaseConfig = {
  apiKey: "AIzaSyCHvhhB37SeYSNUU7rSkruYtZPa8wPflrE",
  authDomain: "manzan-live.firebaseapp.com",
  databaseURL: "https://manzan-live.firebaseio.com",
  projectId: "manzan-live",
  storageBucket: "manzan-live.appspot.com",
  messagingSenderId: "265618932561",
  appId: "1:265618932561:web:0ea6de3c1c3ce235f54970",
};

// Main data struct
type Playable = {
  url: string;
  startTime: Date;
  endTime: Date;
};

// Helper
function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const App = () => {
  const playerRef = createRef<ReactPlayer>();
  const [sessionID, setSessionID] = useState("");
  const [userName, setUserName] = useState("");
  const [allUserNames, setAllUserNames] = useState<string[]>([]);
  const [userNameSet, setUserNameSet] = useState(false);
  const [activeListeners, setActiveListeners] = useState("");
  const [allDocIds, setAllDocIds] = useState<string[]>([]);
  const [allUrls, setAllUrls] = useState<string[]>([]);
  const [urls, setUrls] = useState<Playable[]>([]);
  const [liveUrl, setliveUrl] = useState("not_set");
  const [url, setUrl] = useState<Playable | null>(null);
  const [startUrl, setStartUrl] = useState<Playable | null>(null);
  const [customUrl, setCustomUrl] = useState("");
  const [sync, setSync] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [userClickedStarted, setUserClickedStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [volume, setVolume] = useState(0.5);

  const { addToast } = useToasts();

  // Initializations
  useEffect(() => {
    if (!app.apps.length) {
      app.initializeApp(firebaseConfig);
      const SessionID = uuidv4();
      setSessionID(SessionID);
    }
  }, []);

  // Real-time notifications
  useEffect(() => {
    const userRef = app.database().ref("/status/" + sessionID);

    var isOnlineForDatabase = {
      name: "anonym",
      state: "online",
      last_changed: app.database.ServerValue.TIMESTAMP,
    };

    if (sessionID !== "") {
      app
        .database()
        .ref(".info/connected")
        .on("value", function (snapshot) {
          if (snapshot.val() === false) {
            return;
          }
          if (snapshot.val()) {
            userRef
              .onDisconnect()
              .remove()
              .then(() => userRef.set(isOnlineForDatabase));
          }

          // Notification for new anonym user
          app
            .database()
            .ref("/status/")
            .orderByChild("last_changed")
            .startAt(Date.now())
            .on("child_added", function (snapshot) {
              if (snapshot.val() === false) {
                return;
              }
              var updatedChildData = snapshot.val();
              addToast(updatedChildData.name + " joined 🚀");
            });

          // Notification for new user name
          app
            .database()
            .ref("/status/")
            .on("child_changed", function (snapshot) {
              if (snapshot.val() === false) {
                return;
              }
              var updatedChildData = snapshot.val();
              if (updatedChildData.name !== "anonym") {
                addToast("welcome, " + updatedChildData.name + " 👻");
              }
            });

          // Notification user left
          app
            .database()
            .ref("/status/")
            .on("child_removed", function (snapshot) {
              if (snapshot.val() === false) {
                return;
              }
              var updatedChildData = snapshot.val();
              addToast(updatedChildData.name + " left 😡");
            });

          app
            .database()
            .ref("/status/")
            .on("value", function (snapshot) {
              if (snapshot.val() === false) {
                return;
              }
              setActiveListeners(snapshot.numChildren().toString());
            });

          app
            .database()
            .ref("/status/")
            .on("value", function (snapshot) {
              if (snapshot.val() === false) {
                return;
              }

              let names: Array<string> = [];
              snapshot.forEach(function (childSnapshot) {
                var childData = childSnapshot.val();
                if (childData.name !== "") {
                  names.push(childData.name);
                }
              });
              setAllUserNames(names);
            });
        });
    }
  }, [sessionID]);

  // Look through the schedule, update current url every second
  useEffect(() => {
    const interval = window.setInterval(() => {
      for (const url of urls) {
        if (
          url.startTime < new Date(new Date().toUTCString()) &&
          url.endTime > new Date(new Date().toUTCString())
        ) {
          setUrl(url);
          break;
        }
      }
    }, 1000);

    return () => window.clearInterval(interval);
  }, [urls]);

  // Get schedule
  useEffect(() => {
    const cancelSubscription = app
      .firestore()
      .collection("schedule")
      .where("end_time", ">", new Date(new Date().toUTCString()))
      .onSnapshot((snapshot) => {
        const newUrls: Playable[] = snapshot.docs.map((doc) => ({
          url: doc.data().url,
          startTime: doc.data().start_time?.toDate(),
          endTime: doc.data().end_time?.toDate(),
        }));

        console.log(urls);
        setUrls(newUrls);
      });

    return cancelSubscription;
  }, [allDocIds]);

  // Get all available urls
  useEffect(() => {
    const cancelSubscription = app
      .firestore()
      .collection("urls")
      .onSnapshot((snapshot) => {
        const allNewDocIds = snapshot.docs.map((doc) => doc.id);
        setAllDocIds(allNewDocIds);
        const allUrls = snapshot.docs.map((doc) => doc.data().url);
        setAllUrls(allUrls);
      });
    // Return a function to cancel subscription
    return cancelSubscription;
  }, []);

  // Get live url
  useEffect(() => {
    app
      .firestore()
      .collection("live_url")
      .doc("live_url")
      .onSnapshot((doc) => {
        const url = doc.data()?.url;
        setliveUrl(url);
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {liveUrl !== "not_set" ? (
          <h1 className="font-effect-fire-animation">manzan.live</h1>
        ) : (
          <h1>
            {isMuted ? <s>manzan.</s> : <b>manzan.</b>}
            {sync && playing && !isMuted && hasStarted ? (
              <b>live</b>
            ) : (
              <s>live</s>
            )}{" "}
          </h1>
        )}

        <div className="container">
          <button
            className={liveUrl !== "not_set" ? "fire-button" : "button"}
            onClick={() => {
              setVolume(0.5);
              setIsMuted(!isMuted);

              {
                !isMuted ? addToast("sound off 😢") : addToast("sound on 🎉");
              }
            }}
          >
            {!isMuted ? "mute" : "rave"}
          </button>

          {isMobile && !hasStarted ? (
            <button
              className={liveUrl !== "not_set" ? "fire-button" : "button"}
              onClick={() => {
                setUserClickedStarted(true);
                setStartUrl(url);
                setPlaying(true);
                setHasStarted(true);
              }}
            >
              start
            </button>
          ) : null}

          {!sync || !playing ? (
            <button
              className={liveUrl !== "not_set" ? "fire-button" : "button"}
              onClick={() => {
                if (!url) return;
                // playerRef.current?.seekTo(
                //   (new Date(new Date().toUTCString()).getTime() -
                //     url.startTime.getTime()) /
                //     1000
                // );

                if (sync === false) {
                  setSync(true);
                }

                if (playing === false) {
                  setPlaying(true);
                }
              }}
            >
              live
            </button>
          ) : null}

          {liveUrl === "not_set" ? (
            <button
              onClick={() => {
                if (sync === true) {
                  setSync(false);
                }

                if (playing === false) {
                  setPlaying(true);
                }

                const randomUrl =
                  allUrls[Math.floor(Math.random() * allUrls.length)];
                setCustomUrl(randomUrl);

                setHasStarted(true);
              }}
            >
              {sync ? "rand" : "next"}
            </button>
          ) : null}
        </div>

        <div className="container">
          <div className="player-wrapper">
            <ReactPlayer
              ref={playerRef}
              url={(() => {
                if (isMobile && !userClickedStarted && sync) {
                  if (liveUrl !== "not_set") {
                    return liveUrl;
                  } else {
                    return startUrl?.url;
                  }
                }
                if (isMobile && userClickedStarted && sync) {
                  if (liveUrl !== "not_set") {
                    return liveUrl;
                  } else {
                    return startUrl?.url;
                  }
                }
                if (url?.url && sync === true) {
                  if (liveUrl !== "not_set") {
                    return liveUrl;
                  } else {
                    return url?.url;
                  }
                }
                if (sync === false) {
                  return customUrl;
                }
                return "";
              })()}
              muted={isMuted}
              playing={playing}
              volume={volume}
              onStart={() => setHasStarted(true)}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              onError={(e) => {
                console.log("Error - could't play", customUrl, "playing next");
                if (url?.url && sync) {
                  setUrl(url);
                } else if (sync === false) {
                  const randomUrl =
                    allUrls[Math.floor(Math.random() * allUrls.length)];
                  setCustomUrl(randomUrl);
                }
              }}
              onEnded={() => {
                if (url?.url && sync) {
                  setUrl(url);
                } else if (sync === false) {
                  const randomUrl =
                    allUrls[Math.floor(Math.random() * allUrls.length)];
                  setCustomUrl(randomUrl);
                }
              }}
              onDuration={(durationInSeconds) => {
                if (!url) return;

                if (sync) {
                  const timeSinceStart =
                    (new Date(new Date().toUTCString()).getTime() -
                      url.startTime.getTime()) /
                    1000;

                  playerRef.current?.seekTo(timeSinceStart, "seconds");
                } else if (sync === false) {
                  playerRef.current?.seekTo(0, "seconds");
                }
                return;
              }}
              width="100%"
              height="100%"
              style={{
                top: 0,
                left: 0,
                position: "absolute",
              }}
            />
          </div>
        </div>

        <div
          className={liveUrl !== "not_set" ? "font-effect-fire-animation" : ""}
        >
          <p>
            🎧 ravers online: <b>{activeListeners}</b>{" "}
            {allUserNames.length > 0 ? (
              <span style={{ color: "#ffffff" }}>
                <b>
                  [
                  {allUserNames.map((item, index) => (
                    <span key={`${index}`}>{(index ? ", " : "") + item}</span>
                  ))}
                  ]
                </b>
              </span>
            ) : null}
          </p>
          {!userNameSet ? (
            <form
              onSubmit={(ev) => {
                let name = userName;
                if (name === "") {
                  name = "anonym";
                }
                if (name.length > 15) {
                  alert("Please keep name.length <= 15 😉");
                  ev.preventDefault();
                  // TODO: Reset input text box to ""
                  // var element = (ev.target as HTMLInputElement).value
                  // element.reset()
                  return false;
                }
                name = name.replace(/[^a-zA-Z0-9]/g, "");
                ev.preventDefault();
                setIsLoading(true);
                app
                  .database()
                  .ref("/status/" + sessionID)
                  .update({
                    name: name,
                  })
                  .then(() => setIsLoading(false));

                setUserNameSet(true);
              }}
            >
              <input
                className={liveUrl !== "not_set" ? "fire-input" : "button"}
                type="text"
                placeholder="your name?"
                value={userName}
                onChange={(ev) => setUserName(ev.target.value)}
              />
              <button
                className={liveUrl !== "not_set" ? "fire-button" : "button"}
                type="submit"
                disabled={isLoading}
              >
                done
              </button>
            </form>
          ) : null}
          <p>
            🎼 tunes available: <b>{allDocIds.length}</b> 🦄{" "}
            <a
              href="https://manzan.live/api/v1/docs"
              style={{
                color: "#0ff092",
              }}
            >
              rest api
            </a>
          </p>

          <p></p>
          {isMobile ? (
            <p style={{ fontSize: "small" }}> ✨ better on desktop ✨</p>
          ) : null}
        </div>

        <div className="containersoundcloud">
          <iframe
            width="100%"
            height="20"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/987028729&color=%230ff092&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
          ></iframe>
        </div>
      </header>
    </div>
  );
};

export default App;
