import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ToastProvider, AppearanceTypes } from "react-toast-notifications";
import { isMobile } from "react-device-detect";

interface IProps {
  children: ReactNode;
  appearance: AppearanceTypes;
  // any other props that come into the component
}

const MyCustomToast = ({ appearance, children, ...props }: IProps) => (
  <div
    style={{
      background: "#ffa69e",
      padding: "12px 20px",
      border: "2px solid #ffffff",
      borderRadius: "12px",
      color: "rgb(0, 0, 0)",
      opacity: "0.95",
      paddingBottom: "12px 20px",
      textAlign: "center",
      width: "210px",
      fontSize: "16px",
      fontFamily: "Crimson Pro",
      margin: "4px 8px",
    }}
  >
    {children}
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider
      autoDismiss
      autoDismissTimeout={5000}
      components={{ Toast: MyCustomToast }}
      placement={!isMobile ? "top-right" : "top-center"}
    >
      <App />
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
